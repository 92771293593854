import NewYear2025 from "./NewYear2025/NewYear2025";

const EventsList = [
  {
    id: 1,
    name: "New Year 2025",
    description: "Celebrate the New Year with a Gacha Game!",
    imageUrl: "/assets/events/new_year_2025.webp",
    component: NewYear2025,
    requirement: 195,
  },
  // {
  //   id: 2,
  //   name: "Spring Festival",
  //   description: "Join the Spring Festival and earn rewards!",
  //   imageUrl: "/assets/events/spring_festival.webp",
  //   component: () => <div>Spring Festival Coming Soon!</div>, 
  // },
];

export default EventsList;
