import React from "react";
import {
  Box,
  Modal,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
} from "@mui/material";

const EventItemsModal = ({ open, onClose, eventItems, userInventoryRef }) => {
  const isItemInInventory = (itemId) =>
    userInventoryRef.current.itemDetails.some(
      (item) => String(item.itemId) === String(itemId)
    );

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="event-items-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxHeight: "80vh",
          bgcolor: "white",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            mb: 2,
            fontFamily: '"Pixelify Sans", sans-serif',
          }}
        >
          Event Items
        </Typography>
        <Grid container spacing={2}>
          {[...eventItems]
            .sort((a, b) => b.probability - a.probability) 
            .map((item) => {
              const isOwned = isItemInInventory(item.id);
              return (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Card
                    sx={{
                      filter: isOwned ? "none" : "grayscale(100%) blur(2px)",
                      border: "2px solid rgba(173, 216, 230)",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={item.imageUrl}
                      alt={item.name}
                      sx={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography
                        variant="body1"
                        noWrap
                        sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}
                      >
                        Odds:{" "}
                        {item.probability / 100 < 1 / 1000000
                          ? "???"
                          : `1/${(1 / (item.probability / 100))
                              .toFixed(0)
                              .toLocaleString()}`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Modal>
  );
};

export default EventItemsModal;
