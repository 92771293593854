import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
} from "@mui/material";

const EventCard = ({ event, onSelectEvent, userInventoryRef }) => {
  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: 360,
        margin: "auto",
        mb: 2,
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: 175,
          height: 175,
          objectFit: "cover",
          display: "block",
          margin: "auto",
        }}
        image={event.imageUrl || "/assets/events/default_event.jpg"}
        alt={event.name}
      />
      <CardContent
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            mb: 1,
            textAlign: "center",
            fontFamily: '"Pixelify Sans", sans-serif',
          }}
        >
          {event.name}
        </Typography>
        <Typography sx={{ mb: 1, textAlign: "center" }}>
          {event.description}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onSelectEvent}
          disabled={
            event.requirement &&
            !userInventoryRef.current?.itemDetails.some(
              (item) =>
                String(item.itemId) === String(event.requirement) &&
                item.quantity > 0
            )
          }
        >
          Play
        </Button>
      </CardContent>
    </Card>
  );
};

export default EventCard;
