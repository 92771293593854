const eventItems = [
  {
    id: 141,
    name: "New Year 2025 Coin",
    imageUrl: "/assets/items/currencies/event-newyear2025-coin.webp",
    probability: 35,
    range: [1, 50],
  },
  {
    id: 142,
    name: "New Year Stone",
    imageUrl: "/assets/items/materials/newyear-stone.webp",
    probability: 50,
  },
  {
    id: 143,
    name: "New Year Wheat",
    imageUrl: "/assets/items/farm/newyear-wheat.webp",
    probability: 10,
  },
  {
    id: 144,
    name: "New Year Wheat Seeds",
    imageUrl: "/assets/items/forage/newyear-wheat-seed.webp",
    probability: 0.1,
  },
  {
    id: 145,
    name: "New Year Rocket",
    imageUrl: "/assets/items/materials/newyear-rocket.webp",
    probability: 0.01,
  },
  {
    id: 146,
    name: "New Year Chicken",
    imageUrl: "/assets/items/animal/adult-newyear-chicken.webp",
    probability: 0.001,
  },
  {
    id: 147,
    name: "Huge New Year Chicken",
    imageUrl: "/assets/items/animal/adult-huge-newyear-chicken.webp",
    probability: 0.00001,
  },
  {
    id: 148,
    name: "Huge Golden New Year Chicken",
    imageUrl: "/assets/items/animal/adult-golden-huge-newyear-chicken.webp",
    probability: 0.0000001,
  },
  {
    id: 149,
    name: "Huge Rainbow New Year Chicken",
    imageUrl: "/assets/items/animal/adult-rainbow-huge-newyear-chicken.webp",
    probability: 0.000000001,
  },
  {
    id: 160,
    name: "New Year Sand",
    imageUrl: "/assets/items/materials/newyear-sand.webp",
    probability: 40,
  },
  {
    id: 161,
    name: "New Year Coal",
    imageUrl: "/assets/items/materials/newyear-coal.webp",
    probability: 2.22,
  },
  {
    id: 162,
    name: "New Year Sulfur",
    imageUrl: "/assets/items/materials/newyear-sulfur.webp",
    probability: 2,
  },
  {
    id: 163,
    name: "New Year 2025 Key",
    imageUrl: "/assets/items/keys/newyear2025-key.webp",
    probability: 0.0001,
  },
  {
    id: 164,
    name: "New Year Worm",
    imageUrl: "/assets/items/materials/newyear-worm.webp",
    probability: 1,
  },
  {
    id: 165,
    name: "New Year Fish",
    imageUrl: "/assets/items/fish/newyear-fish.webp",
    probability: 0.2,
  },
  {
    id: 166,
    name: "New Year 2025 Gift",
    imageUrl: "/assets/items/gift/newyear2025-gift.webp",
    probability: 0.002,
  },
  {
    id: 196,
    name: "New Year Wood Snake",
    imageUrl: "/assets/items/animal/adult-newyear-wood-snake.webp",
    probability: 0.0002,
  },
  {
    id: 197,
    name: "Huge New Year Wood Snake",
    imageUrl: "/assets/items/animal/adult-huge-newyear-wood-snake.webp",
    probability: 0.0000008, 
  },
  {
    id: 198,
    name: "Huge Golden New Year Wood Snake",
    imageUrl: "/assets/items/animal/adult-golden-huge-newyear-wood-snake.webp",
    probability: 0.0000002857, 
  },
  {
    id: 199,
    name: "Huge Rainbow New Year Wood Snake",
    imageUrl: "/assets/items/animal/adult-rainbow-huge-newyear-wood-snake.webp",
    probability: 0.0000002, 
  },
  {
    id: 200,
    name: "Titanic New Year Wood Snake",
    imageUrl: "/assets/items/animal/adult-titanic-newyear-wood-snake.webp",
    probability: 0.0000000008696,
  },
  {
    id: 201,
    name: "Titanic New Year Chicken",
    imageUrl: "/assets/items/animal/adult-titanic-newyear-chicken.webp",
    probability: 0.000000001, 
  },
];

export default eventItems;
