import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Slider from "react-slick";
import EventCard from "./EventCard";
import EventsList from "./EventsList";
import SecureLS from "secure-ls";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EventTab = ({ userInventory, userInventoryRef, onBackToSkills }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentEvent, setCurrentEvent] = useState(null);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    setUserId(userDetails ? userDetails.userId : null);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleBackToEvents = () => {
    setCurrentEvent(null);
  };

  const filteredEvents = EventsList.filter((event) =>
    event.name.toLowerCase().includes(searchTerm)
  );

  return (
    <>
      {currentEvent ? (
        <currentEvent.component
          userInventory={userInventory}
          userId={userId}
          userInventoryRef={userInventoryRef}
          onBackToEvents={handleBackToEvents}
        />
      ) : (
        <div style={{ padding: "0 20px", margin: "auto", maxWidth: "400px" }}>
          <div style={{ textAlign: "center" }}>
            <h2 style={{ color: "green" }}>Events</h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <IconButton onClick={onBackToSkills}>
              {" "}
              <ArrowBack />
            </IconButton>
            <TextField
              label="Search Events"
              variant="outlined"
              margin="normal"
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: "20px" },
              }}
              style={{ width: "266px", marginLeft: "10px" }}
            />
          </div>
          <div style={{ marginTop: "7px" }}>
            {filteredEvents.length > 0 ? (
              <Slider
                dots={true}
                infinite={false}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                vertical={true}
                verticalSwiping={true}
              >
                {filteredEvents.map((event) => (
                  <EventCard
                    key={event.id}
                    event={event}
                    onSelectEvent={() => setCurrentEvent(event)}
                    userInventoryRef={userInventoryRef}
                    userId={userId}
                    disabled={
                      event.requirement &&
                      !userInventoryRef.current?.itemDetails.some(
                        (item) =>
                          String(item.itemId) === String(event.requirement) &&
                          item.quantity > 0
                      )
                    }
                  />
                ))}
              </Slider>
            ) : (
              <Typography textAlign="center" mt={2}>
                No events available or no match found.
              </Typography>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EventTab;
