export const getHatchingDelay = (hatchingTier) => {
  switch (hatchingTier) {
    case 1:
      return 2500;
    case 2:
      return 2000;
    case 3:
      return 1250;
    case 4:
      return 1000;
    case 5:
      return 900;
    case 6:
      return 800;
    case 7:
      return 700;
    case 8:
      return 600;
    case 9:
      return 550;
    case 10:
      return 500;
    default:
      return 3000;
  }
};

export const adjustOddsForLuck = (items, luckTier) => {
  const luckIncreasePercentages = {
    1: 5,
    2: 10,
    3: 15,
    4: 20,
    5: 25,
    6: 30,
    7: 35,
    8: 40,
    9: 45,
    10: 50,
  };

  const increasePercentage = luckIncreasePercentages[luckTier] || 0;

  return items.map((item) => {
    const odds = item.probability / 100;
    if (odds >= 1 / 100000) {
      return item;
    }
    const newOdds = odds * (1 + increasePercentage / 100);
    return {
      ...item,
      probability: newOdds * 100,
    };
  });
};

export const adjustCoinRange = (items, coinTier) => {
  const coinRangeByTier = {
    1: 60,
    2: 70,
    3: 80,
    4: 90,
    5: 100,
    6: 120,
    7: 140,
    8: 160,
    9: 180,
    10: 200,
  };

  const newRange = coinRangeByTier[coinTier] || 50;

  return items.map((item) => {
    if (item.id === 141 && item.range) {
      return {
        ...item,
        range: [item.range[0], newRange],
      };
    }
    return item;
  });
};
