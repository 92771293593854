import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CurrencyFunction from "./Function/CurrencyFunction";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CurrencyTab = ({ userInventoryRef, userId }) => {
  const [currencies, setCurrencies] = useState([]);
  const [fromCurrency, setFromCurrency] = useState("");
  const [toCurrency, setToCurrency] = useState("");
  const [chartData, setChartData] = useState(null);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [days, setDays] = useState(7);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/shop/coin-values`
        );

        const currenciesWithDefaultValues = response.data.map((currency) => ({
          ...currency,
          historicalValues:
            currency.historicalValues && currency.historicalValues.length > 0
              ? currency.historicalValues
              : [currency.currentValue],
        }));

        console.log("Fetched Currencies: ", currenciesWithDefaultValues);
        setCurrencies(currenciesWithDefaultValues);
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchCurrencies();

    console.log("User Inventory: ", userInventoryRef.current.itemDetails);
  }, []);

  useEffect(() => {
    if (fromCurrency && toCurrency) {
      fetchChartData(fromCurrency, toCurrency);
    }
  }, [fromCurrency, toCurrency, days]);

  const handleCurrencyChange = (type, currencyId) => {
    if (type === "from") {
      setFromCurrency(currencyId);
    } else {
      setToCurrency(currencyId);
    }
    setResetTrigger((prev) => !prev);
  };

  const fetchChartData = async (fromCurrencyId, toCurrencyId) => {
    if (String(fromCurrencyId) === String(toCurrencyId)) {
      setChartData(null);
      return;
    }

    setIsLoading(true);
    try {
      const fromCurrency = currencies.find(
        (currency) => String(currency.itemId) === String(fromCurrencyId)
      );
      const toCurrency = currencies.find(
        (currency) => String(currency.itemId) === String(toCurrencyId)
      );

      if (fromCurrency && toCurrency) {
        const endDate = new Date(); // Current date is the last day
        const daysToShow = days;

        // Generate the full date range
        const dateLabels = Array.from(
          { length: daysToShow },
          (_, i) =>
            new Date(endDate.getTime() - (daysToShow - 1 - i) * 86400000)
        );

        const adjustHistoricalValues = (values, daysToShow) => {
          if (!values || values.length === 0) {
            return new Array(daysToShow).fill(0);
          }

          const validValues = values;

          const earliestValue = validValues.length > 0 ? validValues[0] : 0;
          const adjustedValues = new Array(daysToShow).fill(earliestValue);

          for (let i = 0; i < validValues.length && i < daysToShow; i++) {
            adjustedValues[daysToShow - 1 - i] =
              validValues[validValues.length - 1 - i];
          }

          return adjustedValues;
        };

        // Adjust historical values for both currencies
        const fromValues = adjustHistoricalValues(
          fromCurrency.historicalValues,
          daysToShow
        );
        const toValues = adjustHistoricalValues(
          toCurrency.historicalValues,
          daysToShow
        );

        // Calculate ratios for the chart
        const calculatedValues = fromValues.map(
          (fromValue, index) => fromValue / (toValues[index] || 1)
        );

        // Prepare chart data
        const data = {
          labels: dateLabels.map((date) =>
            date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            })
          ),
          datasets: [
            {
              label: `${fromCurrency.name} to ${toCurrency.name}`,
              data: calculatedValues,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              fill: true,
            },
          ],
        };

        setChartData(data);
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getEmptyChartData = () => {
    const labels = Array.from({ length: days }, (_, i) =>
      new Date(
        new Date().getTime() - (days - 1 - i) * 86400000
      ).toLocaleDateString()
    );
    return {
      labels,
      datasets: [
        {
          label: "No Data",
          data: Array.from({ length: days }, () => 0),
          borderColor: "rgba(0, 0, 0, 1)",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          fill: true,
        },
      ],
    };
  };

  const ownedCurrencies = userInventoryRef.current.itemDetails;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card
        sx={{
          width: "80%",
          maxWidth: "900px",
          padding: 4,
          margin: 2,
          backgroundColor: "rgba(255,255,255,0.85)",
          boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{ marginBottom: 3, textAlign: "center", fontWeight: "bold" }}
          >
            Currency Trading
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              marginBottom: 4,
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box>
                <Typography>Select From Currency</Typography>
                <Select
                  value={fromCurrency}
                  onChange={(e) => handleCurrencyChange("from", e.target.value)}
                  fullWidth
                >
                  {currencies
                    .filter((currency) =>
                      ownedCurrencies.some(
                        (item) =>
                          String(item.itemId) === String(currency.itemId)
                      )
                    )
                    .map((currency) => (
                      <MenuItem key={currency.itemId} value={currency.itemId}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Avatar
                            src={currency.imageUrl}
                            alt={currency.name}
                            sx={{ width: 24, height: 24 }}
                          />
                          {currency.name}
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box>
                <Typography>Select To Currency</Typography>
                <Select
                  value={toCurrency}
                  onChange={(e) => handleCurrencyChange("to", e.target.value)}
                  fullWidth
                >
                  {currencies
                    .filter(
                      (currency) =>
                        String(currency.itemId) !== "141" && 
                        String(currency.itemId) !== String(fromCurrency) 
                    )
                    .map((currency) => {
                      const isDisabled = !ownedCurrencies.some(
                        (item) =>
                          String(item.itemId) === String(currency.itemId) // Normalize itemId to string
                      );

                      return (
                        <MenuItem
                          key={currency.itemId}
                          value={currency.itemId}
                          disabled={isDisabled}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Avatar
                              src={currency.imageUrl}
                              alt={currency.name}
                              sx={{ width: 24, height: 24 }}
                            />
                            {currency.name}
                          </Box>
                        </MenuItem>
                      );
                    })}
                </Select>
              </Box>
            </Box>
            <Box>
              <Typography>Select Days</Typography>
              <Select
                value={days}
                onChange={(e) => setDays(parseInt(e.target.value))}
                fullWidth
              >
                <MenuItem value={7}>7 Days</MenuItem>
                <MenuItem value={15}>15 Days</MenuItem>
                <MenuItem value={30}>30 Days</MenuItem>
              </Select>
            </Box>
          </Box>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ maxWidth: "100%", marginBottom: 4 }}>
              <Line data={chartData || getEmptyChartData()} />
            </Box>
          )}
          {fromCurrency && toCurrency && (
            <CurrencyFunction
              currencies={currencies}
              fromCurrency={fromCurrency}
              toCurrency={toCurrency}
              userInventoryRef={userInventoryRef}
              userId={userId}
              resetTrigger={resetTrigger}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CurrencyTab;
