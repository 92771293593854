import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EggCrackAnimation from "./components/EggCrackAnimation";
import Inventory from "./components/Inventory";
import Upgrades from "./components/Upgrades";
import eventItems from "./components/EventItems";
import EventItemsModal from "./components/EventItemsModal";

const NewYear2025 = ({ userInventoryRef, onBackToEvents, userId }) => {
  const [autoHatchEnabled, setAutoHatchEnabled] = useState(true);
  const navigate = useNavigate();
  const [isHatching, setIsHatching] = useState(false);
  const [inventoryDialogOpen, setInventoryDialogOpen] = useState(false);
  const [upgradesDialogOpen, setUpgradesDialogOpen] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [purchasedUpgrades, setPurchasedUpgrades] = useState([]);
  const [eventItemsDialogOpen, setEventItemsDialogOpen] = useState(false);

  useEffect(() => {
    fetchUpgrades();
  }, [userId]);

  const fetchUpgrades = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/event/${userId}/NewYear2025`
      );
      if (response.ok) {
        const data = await response.json();
        const upgrades = data.upgrades || [];
        setPurchasedUpgrades(upgrades);
      } else {
        console.error("Failed to fetch upgrades:", await response.text());
      }
    } catch (error) {
      console.error("Error fetching upgrades:", error);
    }
  };

  const handleCrackComplete = () => {
    setIsHatching(false);
  };

  const handleOpenInventory = () => {
    setInventoryDialogOpen(true);
  };

  const handleCloseInventory = () => {
    setInventoryDialogOpen(false);
  };

  const handleOpenUpgrades = () => {
    setUpgradesDialogOpen(true);
  };

  const handleCloseUpgrades = () => {
    setUpgradesDialogOpen(false);
  };

  const handleOpenEventItems = () => {
    setEventItemsDialogOpen(true);
  };

  const handleCloseEventItems = () => {
    setEventItemsDialogOpen(false);
  };

  const updateInventory = (items) => {
    setInventory((prevInventory) => {
      const updatedInventory = [...prevInventory];
      items.forEach((item) => {
        const existingItem = updatedInventory.find((i) => i.id === item.id);
        if (existingItem) {
          existingItem.quantity += item.quantity || 1;
        } else {
          updatedInventory.push({ ...item, quantity: item.quantity || 1 });
        }
      });
      return updatedInventory;
    });
  };

  const handleStoreItem = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/event/event-item/${userId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            items: inventory.map((item) => ({
              itemId: item.id,
              quantity: item.quantity,
            })),
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);

        setInventory([]);

        inventory.forEach((item) => {
          const existingItem = userInventoryRef.current.itemDetails.find(
            (i) => String(i.itemId) === String(item.id)
          );
          if (existingItem) {
            existingItem.quantity += item.quantity;
          } else {
            userInventoryRef.current.itemDetails.push({
              itemId: item.id,
              itemName: item.name,
              itemLevel: 1,
              itemType: "material",
              imageUrl: item.imageUrl,
              quantity: item.quantity,
              itemExp: 0,
              expNeeded: 100,
              subtags: [],
            });
          }
        });
      } else {
        console.error("Failed to store items:", await response.text());
      }
    } catch (error) {
      console.error("Error storing items:", error);
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 3 }}>
      <Box sx={{ position: "absolute", top: 85, left: 16 }}>
        <IconButton onClick={onBackToEvents}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontFamily: '"Pixelify Sans", sans-serif',
          textAlign: "center",
          color: "red",
        }}
      >
        🎉 New Year 2025 Gacha Event 🎉
      </Typography>

      <Box
        sx={{
          textAlign: "center",
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        {!isHatching && (
          <EggCrackAnimation
            onCrackComplete={handleCrackComplete}
            hatchingTier={
              purchasedUpgrades.find(
                (upgrade) => upgrade.upgradeName === "Quicker Egg Hatching"
              )?.upgradeTier || 0
            }
            moreEggsTier={
              purchasedUpgrades.find(
                (upgrade) => upgrade.upgradeName === "More Eggs!"
              )?.upgradeTier || 0
            }
            updateInventory={updateInventory}
            autoHatch={
              !!purchasedUpgrades.find(
                (upgrade) => upgrade.upgradeName === "Auto Hatch"
              ) && autoHatchEnabled
            }
            luckTier={
              purchasedUpgrades.find(
                (upgrade) => upgrade.upgradeName === "Increase Luck!"
              )?.upgradeTier || 0
            }
            coinTier={
              purchasedUpgrades.find(
                (upgrade) => upgrade.upgradeName === "New Year Coin Increase!"
              )?.upgradeTier || 0
            }
          />
        )}

        <Button
          variant="contained"
          sx={{
            mt: 2,
            backgroundColor: "pink",
            "&:hover": {
              backgroundColor: "darkpink",
            },
          }}
          onClick={handleOpenInventory}
        >
          🛒 Open Inventory
        </Button>

        <Button
          variant="contained"
          color="success"
          sx={{ mt: 2 }}
          onClick={handleOpenUpgrades}
        >
          🔧 Manage Upgrades
        </Button>

        <Button
          variant="contained"
          sx={{
            mt: 2,
            backgroundColor: "lightblue",
            "&:hover": {
              backgroundColor: "dodgerblue",
            },
          }}
          onClick={handleOpenEventItems}
        >
          🎁 View Event Items
        </Button>

        <Inventory
          open={inventoryDialogOpen}
          onClose={handleCloseInventory}
          inventory={inventory}
          onStoreItem={handleStoreItem}
        />

        <Upgrades
          open={upgradesDialogOpen}
          onClose={handleCloseUpgrades}
          purchasedUpgrades={purchasedUpgrades}
          fetchUpgrades={fetchUpgrades}
          userId={userId}
          userInventoryRef={userInventoryRef}
          autoHatchEnabled={autoHatchEnabled}
          setAutoHatchEnabled={setAutoHatchEnabled}
        />

        <EventItemsModal
          open={eventItemsDialogOpen}
          onClose={handleCloseEventItems}
          eventItems={eventItems}
          userInventoryRef={userInventoryRef}
        />
      </Box>
    </Box>
  );
};

export default NewYear2025;
