import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Snackbar,
  CircularProgress,
} from "@mui/material";

const AnimalTypeMenu = ({
  animalTypes,
  setSelectedType,
  userId,
  userInventoryRef,
}) => {
  const [plotData, setPlotData] = useState({ occupied: 0, available: 0 });
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [plotCost, setPlotCost] = useState(3500);
  const [isLoading, setIsLoading] = useState(true);
  const [initializationSuccess, setInitializationSuccess] = useState(false);

  const initializePlots = async () => {
    let attempts = 0;
    let success = false;

    while (attempts < 3 && !success) {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/initialize`
        );
        const data = await response.json();

        const newPlotData = animalTypes.reduce((acc, type) => {
          const typeLower = type.toLowerCase();
          const occupied = data.filter((plot) => {
            const animalName = plot.animalDetails.animalName || "";
            const lastName = animalName
              .split(" ")
              .slice(-2)
              .join(" ")
              .toLowerCase();
            return (
              plot.animalDetails.addedAnimalId !== null &&
              lastName === typeLower
            );
          }).length;

          const available = data.filter((plot) => {
            const animalName = plot.animalDetails.animalName || "";
            const lastName = animalName
              .split(" ")
              .slice(-2)
              .join(" ")
              .toLowerCase();
            return (
              plot.animalDetails.addedAnimalId === null ||
              lastName === typeLower
            );
          }).length;

          acc[type] = { occupied, available: available - occupied };
          return acc;
        }, {});

        setPlotData(newPlotData);

        const newPlotCost = 3500 + data.length * 3500 * 0.35;
        setPlotCost(newPlotCost);

        success = true;
        setInitializationSuccess(true);
      } catch (error) {
        attempts += 1;
        console.error("Error initializing animal plots:", error);
      } finally {
        setIsLoading(false);
      }
    }

    if (!success) {
      setSnackbar({
        open: true,
        message: "Failed to initialize plots after multiple attempts.",
      });
    }
  };

  useEffect(() => {
    if (userId && !initializationSuccess) {
      initializePlots();
    }
  }, [userId, animalTypes, initializationSuccess]);

  const handleBuyPlot = async () => {
    const coinItemId = 71;
    const coins = userInventoryRef.current.itemDetails.find(
      (item) => parseInt(item.itemId) === parseInt(coinItemId)
    );

    if (!coins || coins.quantity < plotCost) {
      setSnackbar({ open: true, message: "Insufficient coins to buy plot." });
      return;
    }

    setIsLoading(true);
    setSnackbar({ open: true, message: "Purchasing new plot..." });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/buyPlot`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ plotCost }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setPlotData((prev) => ({ ...prev, available: prev.available + 1 }));
        setSnackbar({ open: true, message: "Plot purchased successfully." });

        const updatedInventory = userInventoryRef.current.itemDetails.map(
          (item) => {
            if (parseInt(item.itemId) === parseInt(coinItemId)) {
              return { ...item, quantity: item.quantity - plotCost };
            }
            return item;
          }
        );
        userInventoryRef.current = {
          ...userInventoryRef.current,
          itemDetails: updatedInventory,
        };

        await initializePlots();
      } else {
        setSnackbar({ open: true, message: data.message });
      }
    } catch (error) {
      console.error("Error purchasing plot:", error);
      setSnackbar({ open: true, message: "Failed to purchase plot." });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleBuyPlot}
          sx={{
            maxWidth: "fit-content",
            minWidth: "150px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Buy Plot for {plotCost.toLocaleString()}
          <img
            src="assets/items/currencies/ranch-coin.webp"
            alt="Ranch Coin"
            style={{ width: 20, height: 20, marginLeft: 4, marginRight: 4 }}
          />
        </Button>
      </Box>
      {animalTypes
        .filter((type) => type.toLowerCase() !== "new")
        .map((type) => (
          <Card key={type} sx={{ display: "flex", mb: 2 }}>
            <CardMedia
              component="img"
              sx={{ width: 151, border: "1px solid black", margin: "5px" }}
              image={`assets/items/animal/adult-${type.toLowerCase()}.webp`}
              onError={(e) => {
                e.target.onerror = null;

                const fallbackAnimal =
                  userInventoryRef.current.itemDetails.find(
                    (item) =>
                      item.itemType === "animal" &&
                      item.itemName.toLowerCase().includes(type.toLowerCase())
                  );

                e.target.src = fallbackAnimal
                  ? `${process.env.PUBLIC_URL}${fallbackAnimal.imageUrl}`
                  : `assets/items/animal/${type.toLowerCase()}.webp`; 
              }}
              alt={`${type} Ranch`}
            />
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5" align="center">
                  {`${type} Ranch`}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  Occupied Plots: {plotData[type]?.occupied || 0}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  Available Plots: {plotData[type]?.available || 0}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSelectedType(type)}
                  sx={{
                    mt: 2,
                    maxWidth: "fit-content",
                    minWidth: "100px",
                    mx: "auto",
                    display: "block",
                  }}
                >
                  Go!
                </Button>
              </CardContent>
            </Box>
          </Card>
        ))}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default AnimalTypeMenu;
