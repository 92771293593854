import fasterEggImage from "../assets/fasteregg.webp";
import moreEggsImage from "../assets/moreeggs.webp";
import autoHatchImage from "../assets/autohatch.webp";
import coinincrease from "../assets/coinincrease.webp";
import luckyegg from "../assets/luckyegg.webp";

const eventUpgrades = [
  {
    upgradeName: "Quicker Egg Hatching",
    imagePath: fasterEggImage,
    upgradeTiers: [
      { tier: 1, cost: 100, currencyItemId: 141 },
      { tier: 2, cost: 1000, currencyItemId: 141 },
      { tier: 3, cost: 10000, currencyItemId: 141 },
      { tier: 4, cost: 30000, currencyItemId: 141 },
      { tier: 5, cost: 100000, currencyItemId: 141 },
      { tier: 6, cost: 250000, currencyItemId: 141 },
      { tier: 7, cost: 500000, currencyItemId: 141 },
      { tier: 8, cost: 1000000, currencyItemId: 141 },
      { tier: 9, cost: 3000000, currencyItemId: 141 },
      { tier: 10, cost: 5000000, currencyItemId: 141 },
    ],
  },
  {
    upgradeName: "More Eggs!",
    imagePath: moreEggsImage,
    upgradeTiers: [
      { tier: 1, cost: 1000, currencyItemId: 141 },
      { tier: 2, cost: 10000, currencyItemId: 141 },
      { tier: 3, cost: 30000, currencyItemId: 141 },
      { tier: 4, cost: 50000, currencyItemId: 141 },
      { tier: 5, cost: 100000, currencyItemId: 141 },
      { tier: 6, cost: 150000, currencyItemId: 141 },
      { tier: 7, cost: 300000, currencyItemId: 141 },
      { tier: 8, cost: 500000, currencyItemId: 141 },
      { tier: 9, cost: 750000, currencyItemId: 141 },
      { tier: 10, cost: 1000000, currencyItemId: 141 },
      { tier: 11, cost: 2000000, currencyItemId: 141 },
      { tier: 12, cost: 5000000, currencyItemId: 141 },
      { tier: 13, cost: 10000000, currencyItemId: 141 },
      { tier: 14, cost: 15000000, currencyItemId: 141 },
      { tier: 15, cost: 20000000, currencyItemId: 141 },
    ],
  },
  {
    upgradeName: "Increase Luck!",
    imagePath: luckyegg,
    upgradeTiers: [
      { tier: 1, cost: 300000, currencyItemId: 141 },
      { tier: 2, cost: 500000, currencyItemId: 141 },
      { tier: 3, cost: 1000000, currencyItemId: 141 },
      { tier: 4, cost: 2000000, currencyItemId: 141 },
      { tier: 5, cost: 3000000, currencyItemId: 141 },
      { tier: 6, cost: 5000000, currencyItemId: 141 },
      { tier: 7, cost: 7500000, currencyItemId: 141 },
      { tier: 8, cost: 10000000, currencyItemId: 141 },
      { tier: 9, cost: 20000000, currencyItemId: 141 },
      { tier: 10, cost: 30000000, currencyItemId: 141 },
    ],
  },
  {
    upgradeName: "New Year Coin Increase!",
    imagePath: coinincrease,
    upgradeTiers: [
      { tier: 1, cost: 100000, currencyItemId: 141 },
      { tier: 2, cost: 200000, currencyItemId: 141 },
      { tier: 3, cost: 300000, currencyItemId: 141 },
      { tier: 4, cost: 400000, currencyItemId: 141 },
      { tier: 5, cost: 750000, currencyItemId: 141 },
      { tier: 6, cost: 1000000, currencyItemId: 141 },
      { tier: 7, cost: 3000000, currencyItemId: 141 },
      { tier: 8, cost: 5000000, currencyItemId: 141 },
      { tier: 9, cost: 7500000, currencyItemId: 141 },
      { tier: 10, cost: 10000000, currencyItemId: 141 },
    ],
  },

  {
    upgradeName: "Auto Hatch",
    imagePath: autoHatchImage,
    upgradeTiers: [{ tier: 1, cost: 3000000, currencyItemId: 141 }],
  },
];

export default eventUpgrades;
