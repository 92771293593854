import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  LinearProgress,
  Card,
  CardContent,
  Switch,
  FormControlLabel,
} from "@mui/material";
import eventUpgrades from "./EventUpgrades";
import { getHatchingDelay } from "./UpgradeEffects";

const Upgrades = ({
  open,
  onClose,
  purchasedUpgrades,
  userId,
  fetchUpgrades,
  userInventoryRef,
  autoHatchEnabled,
  setAutoHatchEnabled,
}) => {
  const [isPurchasing, setIsPurchasing] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});

  const handlePurchaseUpgrade = async (
    upgradeName,
    tier,
    cost,
    currencyItemId
  ) => {
    const userCurrencyItem = userInventoryRef.current?.itemDetails.find(
      (item) => item.itemId === String(currencyItemId)
    );

    const userCoinsAvailable = userCurrencyItem ? userCurrencyItem.quantity : 0;

    if (userCoinsAvailable < cost) {
      setErrorMessages((prev) => ({
        ...prev,
        [upgradeName]: "Insufficient coins",
      }));
      return;
    }

    setIsPurchasing(upgradeName);
    setErrorMessages((prev) => ({
      ...prev,
      [upgradeName]: null,
    }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/event/upgrade/${userId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            upgradeName,
            upgradeTier: tier,
            upgradeId: `NewYear2025-${upgradeName}`,
            cost,
            currencyItemId,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);

        if (userCurrencyItem) {
          userCurrencyItem.quantity -= cost;
        }

        fetchUpgrades();
      } else {
        const errorText = await response.text();
        setErrorMessages((prev) => ({
          ...prev,
          [upgradeName]: `Error: ${errorText}`,
        }));
      }
    } catch (error) {
      setErrorMessages((prev) => ({
        ...prev,
        [upgradeName]: `Error: ${error.message}`,
      }));
    } finally {
      setIsPurchasing(null);
    }
  };

  const handleToggleAutoHatch = () => {
    setAutoHatchEnabled((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { border: "2px solid rgba(173, 216, 230)", borderRadius: "8px" },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Event Upgrades</DialogTitle>
      <DialogContent>
        <Card sx={{ mt: 2, p: 2, display: "flex", alignItems: "center" }}>
          <img
            src={
              userInventoryRef.current?.itemDetails.find(
                (item) => item.itemId === "141"
              )?.imageUrl
            }
            alt="New Year Coin"
            style={{
              width: "50px",
              height: "50px",
              marginRight: "16px",
              objectFit: "contain",
            }}
          />
          <Typography variant="h6">
            {`2025 New Year Coin: ${
              userInventoryRef.current?.itemDetails
                .find((item) => item.itemId === "141")
                ?.quantity.toLocaleString() || 0
            }`}
          </Typography>
        </Card>
        {eventUpgrades.map((upgrade) => {
          const maxTier = upgrade.upgradeTiers.length;
          const currentTier =
            purchasedUpgrades.find((u) => u.upgradeName === upgrade.upgradeName)
              ?.upgradeTier || 0;

          const getEffectDescription = () => {
            switch (upgrade.upgradeName) {
              case "Quicker Egg Hatching":
                return `Current Hatch Speed: ${getHatchingDelay(currentTier)/1000} second`;
              case "More Eggs!":
                return `Current eggs per hatch: ${1 + currentTier}`;
              case "Increase Luck!":
                const luckReduction =
                  {
                    1: 5,
                    2: 10,
                    3: 15,
                    4: 20,
                    5: 25,
                    6: 30,
                    7: 35,
                    8: 40,
                    9: 45,
                    10: 50,
                  }[currentTier] || 0;
                return `Luck boost: ${luckReduction}%`;
              case "New Year Coin Increase!":
                const coinRange =
                  {
                    1: 60,
                    2: 70,
                    3: 80,
                    4: 90,
                    5: 100,
                    6: 120,
                    7: 140,
                    8: 160,
                    9: 180,
                    10: 200,
                  }[currentTier] || 50;
                return `Coin range: 1-${coinRange}`;
              case "Auto Hatch":
                return "Automatically hatches eggs on a timer";
              default:
                return "No effect";
            }
          };

          return (
            <Card
              key={upgrade.upgradeName}
              sx={{ mt: 2, p: 2, display: "flex" }}
            >
              <img
                src={upgrade.imagePath}
                alt={upgrade.upgradeName}
                style={{
                  width: "150px",
                  height: "150px",
                  marginRight: "16px",
                  objectFit: "contain",
                }}
              />
              <CardContent sx={{ flex: 1 }}>
                <Typography variant="h6">{upgrade.upgradeName}</Typography>
                <Typography variant="body2" sx={{ color: "gray", mb: 1 }}>
                  {getEffectDescription()}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={(currentTier / maxTier) * 100}
                  sx={{ mt: 1, mb: 1 }}
                />
                <Typography>
                  Tier: {currentTier}/{maxTier}
                </Typography>
                {upgrade.upgradeName === "Auto Hatch" && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={autoHatchEnabled}
                        onChange={handleToggleAutoHatch}
                        color="primary"
                      />
                    }
                    label={`AutoHatch: ${autoHatchEnabled ? "On" : "Off"}`}
                    sx={{ mt: 2 }}
                  />
                )}
                {currentTier < maxTier ? (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() =>
                      handlePurchaseUpgrade(
                        upgrade.upgradeName,
                        currentTier + 1,
                        upgrade.upgradeTiers[currentTier].cost,
                        upgrade.upgradeTiers[currentTier].currencyItemId
                      )
                    }
                    disabled={isPurchasing === upgrade.upgradeName}
                  >
                    {isPurchasing === upgrade.upgradeName
                      ? "Purchasing..."
                      : `Tier ${currentTier + 1} (${upgrade.upgradeTiers[
                          currentTier
                        ].cost.toLocaleString()})`}
                  </Button>
                ) : (
                  <Button variant="outlined" disabled sx={{ mt: 2 }}>
                    Max Tier Reached
                  </Button>
                )}
                {errorMessages[upgrade.upgradeName] && (
                  <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                    {errorMessages[upgrade.upgradeName]}
                  </Typography>
                )}
              </CardContent>
            </Card>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Upgrades;
