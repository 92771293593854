import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

const Inventory = ({ open, onClose, inventory, userId, onStoreItem }) => {
  const [isStoring, setIsStoring] = React.useState(false);

  const handleStoreAllItems = async () => {
    setIsStoring(true);
    try {
      await onStoreItem(inventory);
      onClose();
    } catch (error) {
      console.error("Error storing items:", error);
    } finally {
      setIsStoring(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { border: "2px solid rgba(173, 216, 230)", borderRadius: "8px" },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Your Inventory</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell align="right">Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
              </TableRow>
            ))}
            {inventory.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>No items in your inventory.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleStoreAllItems}
          disabled={isStoring}
        >
          {isStoring ? "Storing..." : "Store All"}
        </Button>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Inventory;
