import React, { useState, useRef } from "react";
import "./styles/EggCrackAnimation.css";
import { Button, Box } from "@mui/material";
import eggImage from "../assets/egg.webp";
import eventItems from "./EventItems";
import {
  getHatchingDelay,
  adjustOddsForLuck,
  adjustCoinRange,
} from "./UpgradeEffects";

const EggCrackAnimation = ({
  onCrackComplete,
  hatchingTier,
  moreEggsTier,
  updateInventory,
  autoHatch,
  luckTier,
  coinTier,
}) => {
  const [isCracking, setIsCracking] = useState(false);
  const [isAutoHatching, setIsAutoHatching] = useState(false);
  const [hatchedItems, setHatchedItems] = useState([]);
  const autoHatchInterval = useRef(null);

  const getRandomItem = () => {
    const adjustedItems = adjustOddsForLuck(
      adjustCoinRange(eventItems, coinTier),
      luckTier
    );

    const totalProbability = adjustedItems.reduce(
      (acc, item) => acc + item.probability,
      0
    );
    const random = Math.random() * totalProbability;

    let cumulative = 0;
    for (const item of adjustedItems) {
      cumulative += item.probability;
      if (random <= cumulative) {
        const selectedItem = { ...item };
        if (selectedItem.range) {
          selectedItem.quantity = Math.floor(
            Math.random() *
              (selectedItem.range[1] - selectedItem.range[0] + 1) +
              selectedItem.range[0]
          );
        } else {
          selectedItem.quantity = 1;
        }
        return selectedItem;
      }
    }
    const fallbackItem = { ...adjustedItems[adjustedItems.length - 1] };
    fallbackItem.quantity = 1;
    return fallbackItem;
  };

  const hatchEggs = () => {
    const eggsToHatch = 1 + moreEggsTier;
    const items = Array.from({ length: eggsToHatch }, getRandomItem);

    const aggregatedItems = items.reduce((acc, item) => {
      const existingItem = acc.find((i) => i.id === item.id);
      if (existingItem) {
        existingItem.quantity += item.quantity || 1;
      } else {
        acc.push({ ...item, quantity: item.quantity || 1 });
      }
      return acc;
    }, []);

    updateInventory(aggregatedItems);

    setHatchedItems(items);
    setTimeout(() => setHatchedItems([]), 1000);
  };

  const handleHatch = () => {
    setIsCracking(true);
    setTimeout(() => {
      hatchEggs();
      setIsCracking(false);
      if (onCrackComplete) onCrackComplete();
    }, getHatchingDelay(hatchingTier));
  };

  const startAutoHatching = () => {
    setIsAutoHatching(true);
    autoHatchInterval.current = setInterval(() => {
      if (!isCracking) {
        handleHatch();
      }
    }, getHatchingDelay(hatchingTier) + 500);
  };

  const cancelAutoHatching = () => {
    setIsAutoHatching(false);
    clearInterval(autoHatchInterval.current);
    autoHatchInterval.current = null;
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "repeat(auto-fit, minmax(120px, 1fr))",
          sm: "repeat(4, 1fr)",
        },
        gap: { xs: "10px", sm: "15px", md: "20px" },
        alignItems: "center",
        justifyItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {Array.from({ length: 1 + moreEggsTier }).map((_, index) => (
        <div
          key={index}
          className="egg-container"
          style={{
            width: "calc(100px + 2vw)",
            height: "calc(100px + 2vw)",
          }}
        >
          {hatchedItems[index] ? (
            <div className="item-container">
              <img
                src={hatchedItems[index].imageUrl}
                alt={hatchedItems[index].name}
                className="item-image"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
              <p className="item-name">
                {hatchedItems[index].name}
                {hatchedItems[index].quantity > 1
                  ? ` [${hatchedItems[index].quantity}]`
                  : ""}
              </p>
            </div>
          ) : (
            <img
              src={eggImage}
              alt="Egg"
              className={`egg ${isCracking ? "cracking" : ""}`}
              onClick={!isCracking ? handleHatch : undefined}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
          gridColumn: "1 / -1",
        }}
      >
        {autoHatch ? (
          <>
            <Button
              variant="contained"
              color="error"
              disabled={isAutoHatching}
              onClick={!isAutoHatching ? startAutoHatching : undefined}
              sx={{
                px: 4,
                py: 1,
                border: "2px solid rainbow",
                marginRight: "8px",
              }}
            >
              {isAutoHatching ? "Auto Hatching..." : "Auto Hatch"}
            </Button>
            {isAutoHatching && (
              <Button
                variant="contained"
                color="error"
                onClick={cancelAutoHatching}
                sx={{ px: 4, py: 1 }}
              >
                Cancel
              </Button>
            )}
          </>
        ) : (
          <Button
            variant="contained"
            color="error"
            onClick={!isCracking ? handleHatch : undefined}
            disabled={isCracking}
            sx={{
              px: 4,
              py: 1,
            }}
          >
            Hatch
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default EggCrackAnimation;
